import React, { useState } from 'react'
import clsx from 'clsx'
import Drawer from '@material-ui/core/Drawer'
import Box from '@material-ui/core/Box'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Container from '@material-ui/core/Container'

// route
import { Route, Switch, Redirect } from "react-router-dom"

// icon
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

// list
import { mainListItems, secondaryListItems } from './listItems'

//styles
import styles from './styles'

// components
import Scheduling from '../../components/Scheduling'
import User from '../../components/User'
import Segment from '../../components/Segment'
import Price from '../../components/Price'
import Coupon from '../../components/Coupon'
import Discipline from '../../components/Discipline'
import Version from '../../components/Version'
import Show from '../../components/Show'

export default function Dashboard() {

  // states
  const [open, setOpen] = useState(true)

  // css
  const classes = styles()

  const handleDrawerOpen = () => { setOpen(true) }
  const handleDrawerClose = () => { setOpen(false) }

  return (
    <div className={classes.root}>

      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>

          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)} >
            <MenuIcon />
          </IconButton>

          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            Dashboard
          </Typography>

        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }} open={open} >

        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>

        <Divider />
        <List>{mainListItems}</List>
        <Divider />
        <List>{secondaryListItems}</List>

      </Drawer>

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>

          {/* Pages */}
          <Switch>

            <Route path="/dashboard" exact
              component={Scheduling} />

            <Route path="/dashboard/user" exact
              component={User} />

            <Route path="/dashboard/segmento"
              component={Segment} />

            <Route path="/dashboard/cupom"
              component={Coupon} />

            <Route path="/dashboard/disciplina"
              component={Discipline} />

            <Route path="/dashboard/preco"
              component={Price} />

            <Route path="/dashboard/versao"
              component={Version} />

            <Route path="/dashboard/agendamento/:class_id"
              component={Show} />

            <Route path="*">
              <Redirect to="/dashboard" />
            </Route>

          </Switch>

          <Box pt={4}>
            <Typography variant="body2" color="textSecondary" align="center">
              {' solucaodiferencial.com '}
            </Typography>
          </Box>

        </Container>
      </main>
    </div>
  )
}
