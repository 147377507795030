import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import Link from '@material-ui/core/Link'

// icons
import { HomeWork, Bookmarks, LocalOffer, Extension, ExitToApp, SupervisorAccount, Android, CreditCard } from '@material-ui/icons'

//styles

export const mainListItems = (
  <div>

    <Link href="/dashboard" color="inherit" style={{ textDecoration: 'none' }}>
      <ListItem button>
        <ListItemIcon>
          <HomeWork />
        </ListItemIcon>
        <ListItemText primary="Agendamentos" />
      </ListItem>
    </Link>

    <Link href="/dashboard/user" color="inherit" style={{ textDecoration: 'none' }}>
      <ListItem button>
        <ListItemIcon>
          <SupervisorAccount />
        </ListItemIcon>
        <ListItemText primary="Usuários" />
      </ListItem>
    </Link>

    <Link href="/dashboard/segmento" color="inherit" style={{ textDecoration: 'none' }}>
      <ListItem button>
        <ListItemIcon>
          <Bookmarks />
        </ListItemIcon>
        <ListItemText primary="Segmentos" />
      </ListItem>
    </Link>

    <Link href="/dashboard/disciplina" color="inherit" style={{ textDecoration: 'none' }}>
      <ListItem button>
        <ListItemIcon>
          <Extension />
        </ListItemIcon>
        <ListItemText primary="Disciplina" />
      </ListItem>
    </Link>

    <Link href="/dashboard/preco" color="inherit" style={{ textDecoration: 'none' }}>
      <ListItem button>
        <ListItemIcon>
          <CreditCard />
        </ListItemIcon>
        <ListItemText primary="Preço" />
      </ListItem>
    </Link>

    <Link href="/dashboard/cupom" color="inherit" style={{ textDecoration: 'none' }}>
      <ListItem button>
        <ListItemIcon>
          <LocalOffer />
        </ListItemIcon>
        <ListItemText primary="Cupom" />
      </ListItem>
    </Link>

  </div>
)

export const secondaryListItems = (
  <div>

    <ListSubheader inset>
      Configurações
    </ListSubheader>

    <Link href="/dashboard/versao" color="inherit" style={{ textDecoration: 'none' }}>
      <ListItem button>
        <ListItemIcon>
          <Android />
        </ListItemIcon>
        <ListItemText primary="Versão" />
      </ListItem>
    </Link>

    <Link href="/logout" color="inherit" style={{ textDecoration: 'none' }}>
      <ListItem button>
        <ListItemIcon>
          <ExitToApp />
        </ListItemIcon>
        <ListItemText primary="Sair" />
      </ListItem>
    </Link>

  </div>
)
