import React from 'react'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/core/styles'

// routes
import Routes from './routes'

// css
import './App.css'

function App() {

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#7159c1',
      },
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Routes />
    </ThemeProvider>
  )
}

export default App
