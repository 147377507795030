import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { Alert } from '@material-ui/lab'
import Title from '../Title'
import jwt_decode from 'jwt-decode'

// icon
import BorderColor from '@material-ui/icons/BorderColor'

//styles
import styles from './styles'

// api
import api from '../../services/api'

export default function Scheduling({ history }) {

    // states
    const [schedulings, setSchedulings] = useState([])
    const [users, setUsers] = useState([])

    // css
    const classes = styles()

    // functions
    function _dateName(user_id) {
        for (var i = 0; i < users.length; i++) {
            let user = users[i].id
            if (user === user_id) {
                const name = users[i].name
                return name
            }
            // return null
        }
    }

    function _dateEmail(user_id) {
        for (var i = 0; i < users.length; i++) {
            let user = users[i].id
            if (user === user_id) {
                const email = users[i].email
                return email
            }
            // return null
        }
    }

    function _datePhone(user_id) {
        for (var i = 0; i < users.length; i++) {
            let user = users[i].id
            if (user === user_id) {
                const phone = users[i].phone
                return phone
            }
            // return null
        }
    }

    useEffect(() => {
        try {
            async function load() {
                const token = await localStorage.getItem('token')
                const { id } = jwt_decode(token)

                const responseClass = await api.get('idx/class', { headers: { admin_id: id } })
                setSchedulings(responseClass.data)

                const responseUser = await api.get('leads/account', { headers: { user_id: id } })
                setUsers(responseUser.data)
            }

            load()
        } catch (e) {
            console.log(e)
        }
    }, [])

    return (
        <>
            <Box mb={5}>
                <Typography
                    component="h1"
                    variant="h6"
                    color="primary"
                    noWrap
                    className={classes.title}>
                    <b>
                        <small>
                            Olá, seja bem vindo(a).
                        </small>
                    </b>
                </Typography>
            </Box>

            <Grid container spacing={3}>

                <Grid item xs={12}>
                    <Paper className={classes.paper}>

                        <React.Fragment>

                            <Box mb={5}>
                                <Title> Agendamentos </Title>
                            </Box>

                            <Box pb={5}>
                                <Box pb={5}>
                                    <Alert severity="success">
                                        <small>
                                            <b>Total de Registros: </b> {schedulings.length}
                                        </small>
                                    </Alert>
                                </Box>

                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nome</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell>Telefone</TableCell>
                                            <TableCell>Solicitação</TableCell>
                                            <TableCell>Registrado em</TableCell>
                                            <TableCell align="right">*</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {schedulings.map(scheduling => (
                                            <TableRow key={scheduling.id}>
                                                <TableCell>
                                                    <span className={classes.textCapitalize}>
                                                        {_dateName(scheduling.idAccount)}
                                                    </span>
                                                </TableCell>
                                                <TableCell>{_dateEmail(scheduling.idAccount)}</TableCell>
                                                <TableCell>{_datePhone(scheduling.idAccount)}</TableCell>
                                                <TableCell>
                                                    {
                                                        (scheduling.status === 'pendente') &&
                                                        <Alert severity="warning" color="warning">
                                                            <span className={classes.status}>
                                                                {scheduling.status}
                                                            </span>
                                                        </Alert>
                                                    }

                                                    {
                                                        (scheduling.status === 'recusado') &&
                                                        <Alert severity="error" color="error">
                                                            <span className={classes.status}>
                                                                {scheduling.status}
                                                            </span>
                                                        </Alert>
                                                    }

                                                    {
                                                        (scheduling.status === 'aceito') &&
                                                        <Alert severity="info" color="info">
                                                            <span className={classes.status}>
                                                                {scheduling.status}
                                                            </span>
                                                        </Alert>
                                                    }

                                                    {
                                                        (scheduling.status === 'finalizado') &&
                                                        <Alert severity="success" color="success">
                                                            <span className={classes.status}>
                                                                {scheduling.status}
                                                            </span>
                                                        </Alert>
                                                    }
                                                </TableCell>
                                                <TableCell>{scheduling.createdAt}</TableCell>
                                                <TableCell align="right">
                                                    <Box mb={1}>
                                                        <IconButton
                                                            onClick={() => {
                                                                history.push(
                                                                    `/dashboard/agendamento/${scheduling.id}`
                                                                )
                                                            }}
                                                            color="primary"
                                                            aria-label="Editar Agendamento">
                                                            <BorderColor />
                                                        </IconButton>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </React.Fragment>

                    </Paper>
                </Grid>

            </Grid>
        </>
    )
}
