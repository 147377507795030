import React, { useState } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Alert, AlertTitle } from '@material-ui/lab'

//img
import logo from '../../assets/logo.png'

//styles
import styles from './styles'

// api
import api from '../../services/api'

export default function Login({ history }) {

  const classes = styles()

  // states
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  // const [success, setSuccess] = useState('')

  // login
  async function login(email, password) {
    const response = await api.post('auth/admin/login', { email, password })

    if (response.data.error) {
      setError(response.data.error)
    } else {
      localStorage.setItem('token', response.data)
      history.push('/dashboard')
    }
  }

  // register
  // async function _register(email, password) {
  //   const response = await api.post('auth/admin/register', { email, password })
  //   return response.data
  // }

  async function hundleSubmit(event) {
    event.preventDefault()

    setError('')
    if ((!email) || (!password)) {
      setError('Preencha todos os campos.')
      return
    }

    // login --
    login(email, password)

    // register --
    // const registered = await _register(email, password)

    // if (registered === true) {
    //   console.log(registered)
    //   setSuccess('Conta registrada com sucesso')
    // } else {
    //   setError(registered)
    // }

  }

  return (
    <>
      {(localStorage.getItem('token')) && history.push('/dashboard')}

      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>

            <Avatar alt="logo" src={logo} />

            <Box mt={2}>
              <Typography component="h1" variant="h5" color="textSecondary">
                Solução Diferencial
            </Typography>
            </Box>

            <form onSubmit={hundleSubmit} className={classes.form} noValidate>

              {
                error !== '' ? (
                  <Box mt={3} mb={2}>
                    <Alert severity="error">
                      <AlertTitle>Alerta</AlertTitle>
                      {error}
                    </Alert>
                  </Box>
                ) : null
              }

              {/* {
                success !== '' ? (
                  <Box mt={3} mb={2}>
                    <Alert severity="success">
                      <AlertTitle>Alerta</AlertTitle>
                      {success}
                    </Alert>
                  </Box>
                ) : null
              } */}

              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="text"
                label="Endereço de Login"
                name="text"
                autoComplete="text"
                autoFocus
                value={email}
                onChange={event => setEmail(event.target.value)}
              />

              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Senha de Entrada"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={event => setPassword(event.target.value)}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    value="remember"
                    color="primary" />
                }
                label="Lembrar usuário"
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Acessar Plataforma
            </Button>

              <Box mt={5}>
                <Typography variant="body2" color="textSecondary" align="center">
                  Solução Diferencial | <b>Área Administrativa</b>
                </Typography>
              </Box>

            </form>

          </div>
        </Grid>
      </Grid>
    </>
  )
}