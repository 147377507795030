import React, { useState, useEffect, useRef } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { Alert, AlertTitle } from '@material-ui/lab'
import Title from '../../components/Title'
import jwt_decode from 'jwt-decode'
import Button from '@material-ui/core/Button'

// icon
import Save from '@material-ui/icons/Save'

//styles
import styles from './styles'

// api
import api from '../../services/api'

export default function Discipline() {

    // states
    const [segments, setSegments] = useState([])
    const [disciplines, setDisciplines] = useState([])
    const [title, setTitle] = useState('')
    const [newTitle, setNewTitle] = useState('')
    const [idSegments, setIdSegments] = useState('')
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')
    const [render, setRender] = useState('')

    const el = useRef(null)

    // css
    const classes = styles()

    function segmentFormat(segment_id) {
        for (var i = 0; i < segments.length; i++) {
            const segment = segments[i].id
            if (segment === segment_id) {
                return segments[i].title
            }
        }
    }

    async function hundleSubmit(event) {
        event.preventDefault()

        setSuccess('')
        setError('')

        if (!title) {
            setError('Preencha o campo de disciplina.')
            setTimeout(() => { setError('') }, 8000)
            return
        }

        if ((!idSegments)) {
            setError('Selecione ao menos um segmento.')
            setTimeout(() => { setError('') }, 8000)
            return
        }

        const token = await localStorage.getItem('token')
        const { id } = jwt_decode(token)

        const response = await api.post('str/discipline',
            { title }
            , {
                headers: {
                    user_id: id, segment_id: idSegments
                }
            }
        )

        if (response.data) {
            setSuccess('cadastrado com sucesso!')
            setRender((new Date()).getMilliseconds())
            setTimeout(() => { setSuccess('') }, 6000)
        } else {
            setError('Erro ao cadastrar! Verifique se essa disciplina já existe.')
            setTimeout(() => { setError('') }, 6000)
        }

        setTitle('')
    }

    async function _disciplineEdit(edit_id, edit_title) {

        setSuccess('')
        setError('')

        if ((!edit_id) || (edit_title === '')) {
            setError('Parece que não ocorreu nenhuma modificação entre a disciplina antiga e a nova.')
            el.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
            setTimeout(() => { setError('') }, 8000)
            return
        }
        
        const token = await localStorage.getItem('token')
        const { id } = jwt_decode(token)
        const response = await api.put(`upd/discipline/${edit_id}`, { title: edit_title }, { headers: { user_id: id } })
        
        if (response.data) {
            setSuccess('Atualizado com sucesso!')
            setRender((new Date()).getMilliseconds())
            el.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
            setTimeout(() => { setSuccess('') }, 8000)
        } else {
            setError('Erro ao atualizar! Verifique se esse segmento já existe.')
            el.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
            setTimeout(() => { setError('') }, 8000)
        }

        setNewTitle('')
    }

    useEffect(() => {

        async function loadDisciplines() {
            const token = await localStorage.getItem('token')
            const { id } = jwt_decode(token)
            const response = await api.get('idx/discipline', { headers: { user_id: id } })

            setDisciplines(response.data)
        }

        async function loadSegments() {
            const token = await localStorage.getItem('token')
            const { id } = jwt_decode(token)
            const response = await api.get('idx/segment', { headers: { user_id: id } })

            setSegments(response.data)
        }

        loadDisciplines()
        loadSegments()

        // setPercent('')
        // setIdSegments('')

    }, [render])

    return (
        <>
            <div id={'el'} ref={el}></div>
            <Box mb={5}>
                <Typography
                    component="h1"
                    variant="h6"
                    color="primary"
                    noWrap
                    className={classes.title}>
                    <b>
                        <small>
                            &#187; Disciplina
                        </small>
                    </b>
                </Typography>
            </Box>

            {/* Components */}
            <Grid container spacing={3}>

                {/* Saved/Update Coupons */}
                <Grid item xs={12}>
                    <Box mb={3}>
                        <Paper className={classes.paper}>
                            <Box mb={3}>
                                <Typography
                                    component="h1"
                                    variant="h6"
                                    color="primary"
                                    className={classes.title}>
                                    <b>
                                        <small>Nova Disciplina</small>
                                    </b>
                                </Typography>

                                <Typography
                                    component="p"
                                    color="textSecondary"
                                    className={classes.title}>
                                    <b>
                                        <small>
                                            Cuidado, está ação não poderá ser desfeita.
                                    </small>
                                    </b>
                                </Typography>
                            </Box>

                            {
                                error !== '' ? (
                                    <Box mt={3} mb={2}>
                                        <Alert severity="error">
                                            <AlertTitle>Alerta</AlertTitle>
                                            {error}
                                        </Alert>
                                    </Box>
                                ) : null
                            }

                            {
                                success !== '' ? (
                                    <Box mt={3} mb={2}>
                                        <Alert severity="success">
                                            <AlertTitle>Alerta</AlertTitle>
                                            {success}
                                        </Alert>
                                    </Box>
                                ) : null
                            }

                            <form onSubmit={hundleSubmit} className={classes.form} noValidate>
                                <Grid container spacing={2}
                                    justify="center"
                                    alignItems="center">
                                    <Grid item
                                        xs={8}
                                        lg={6}
                                        md={8}
                                        sm={12}
                                    >

                                        <Grid item xs={12}>
                                            <Box mb={1}>
                                                <InputLabel>Segmento</InputLabel>
                                            </Box>

                                            <Select
                                                variant="filled"
                                                margin="normal"
                                                required
                                                fullWidth
                                                defaultValue={idSegments}
                                                onChange={event => setIdSegments(event.target.value)}
                                            >
                                                {segments.map(segment => (
                                                    <MenuItem
                                                        key={segment.id}
                                                        value={segment.id}>
                                                        {segment.title}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                fullWidth
                                                label="Disciplina"
                                                value={title}
                                                onChange={event => setTitle(event.target.value)}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Box mt={2}>
                                                <Button
                                                    type="submit"
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.submit}>
                                                    Criar Disciplina </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>

                        </Paper>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Paper className={classes.paper}>

                        <React.Fragment>

                            <Box mb={5}>
                                <Title> Disciplinas cadastradas </Title>
                            </Box>

                            <Box pb={5}>
                                <Box pb={5}>
                                    <Alert severity="success">
                                        <small>
                                            <b>Total de Registros: </b> {disciplines.length}
                                        </small>
                                    </Alert>
                                </Box>

                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Título</TableCell>
                                            <TableCell>Segmento</TableCell>
                                            <TableCell>Registrado em</TableCell>
                                            <TableCell align="right">*</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {disciplines.map(discipline => (
                                            <TableRow key={discipline.id}>
                                                <TableCell>

                                                    <TextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        label="Título"
                                                        defaultValue={discipline.title}
                                                        onChange={event => setNewTitle(event.target.value)}
                                                    />

                                                </TableCell>
                                                <TableCell>{segmentFormat(discipline.idSegment)}</TableCell>
                                                <TableCell>{discipline.createdAt}</TableCell>
                                                <TableCell align="right">
                                                    <Box mb={1}>
                                                        <IconButton
                                                            onClick={() => { _disciplineEdit(discipline.id, newTitle) }}
                                                            color="primary"
                                                            aria-label="Editar Segmento">
                                                            <Save />
                                                        </IconButton>
                                                    </Box>

                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </React.Fragment>

                    </Paper>
                </Grid>

            </Grid>
        </>
    )
}
