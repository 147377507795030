import React, { useState, useEffect, useRef } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { Alert, AlertTitle } from '@material-ui/lab'
import Title from '../../components/Title'
import jwt_decode from 'jwt-decode'
import Button from '@material-ui/core/Button'

// icon
import Save from '@material-ui/icons/Save'

//styles
import styles from './styles'

// api
import api from '../../services/api'

export default function Price() {

    // states
    const [segments, setSegments] = useState([])
    const [disciplines, setDisciplines] = useState([])
    const [prices, setPrices] = useState([])
    const [modality, setModality] = useState('')
    const [disciplineCurrent, setDisciplineCurrent] = useState('')
    const [priceCurrent, setPricesCurrent] = useState('')
    const [newPrice, setNewPrice] = useState('')
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')
    const [render, setRender] = useState('')

    const el = useRef(null)

    // css
    const classes = styles()

    function _titleSegment(segment_id) {
        for (var i = 0; i < segments.length; i++) {
            const segment = segments[i].id
            if (segment === segment_id) {
                return segments[i].title
            }
        }
    }

    function segmentFormat(discipline_id) {
        for (var i = 0; i < disciplines.length; i++) {
            const discipline = disciplines[i].id

            if (discipline === discipline_id) {
                const segment_id = disciplines[i].idSegment
                const title = _titleSegment(segment_id)
                return title
            }
        }
    }

    function disciplineFormat(discipline_id) {
        for (var i = 0; i < disciplines.length; i++) {
            const discipline = disciplines[i].id
            if (discipline === discipline_id) {
                return disciplines[i].title
            }
        }
    }

    async function hundleSubmit(event) {
        event.preventDefault()

        setSuccess('')
        setError('')

        if ((!modality) || (!priceCurrent) || (priceCurrent < 0)) {
            setError('Preencha todos os campos ou coloque um preço válido.')
            setTimeout(() => { setError('') }, 8000)
            return
        }

        if ((!disciplineCurrent)) {
            setError('Selecione ao menos uma disciplina.')
            setTimeout(() => { setError('') }, 8000)
            return
        }

        const token = await localStorage.getItem('token')
        const { id } = jwt_decode(token)

        const response = await api.post('str/price',
            { price: priceCurrent, modality }
            , {
                headers: {
                    user_id: id, id_discipline: disciplineCurrent
                }
            }
        )

        if (response.data) {
            setSuccess('cadastrado com sucesso!')
            setRender((new Date()).getMilliseconds())
            setTimeout(() => { setSuccess('') }, 6000)
        } else {
            setError('Erro ao cadastrar! Verifique se essa modalidade já existe.')
            setTimeout(() => { setError('') }, 6000)
        }

        setModality('')
        setPricesCurrent('')
        setDisciplineCurrent('')
    }

    async function _priceEdit(edit_id, price) {

        setSuccess('')
        setError('')

        if ((!price) || (price < 0)) {
            setError('Parece que não ocorreu nenhuma modificação entre o antigo preço e o novo.')
            el.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
            setTimeout(() => { setError('') }, 8000)
            return
        }

        const token = await localStorage.getItem('token')
        const { id } = jwt_decode(token)
        const response = await api.put(`upd/price/${edit_id}`, { price }, { headers: { user_id: id } })

        if (response.data) {
            setSuccess('Atualizado com sucesso!')
            setRender((new Date()).getMilliseconds())
            el.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
            setTimeout(() => { setSuccess('') }, 8000)
        } else {
            setError('Erro ao atualizar! Verifique se esse segmento já existe.')
            el.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
            setTimeout(() => { setError('') }, 8000)
        }

        setNewPrice('')
    }

    useEffect(() => {

        async function loadDisciplines() {
            const token = await localStorage.getItem('token')
            const { id } = jwt_decode(token)
            const response = await api.get('idx/discipline', { headers: { user_id: id } })

            setDisciplines(response.data)
        }

        async function loadPrices() {
            const token = await localStorage.getItem('token')
            const { id } = jwt_decode(token)
            const response = await api.get('idx/price', { headers: { user_id: id } })

            setPrices(response.data)
        }

        async function loadSegments() {
            const token = await localStorage.getItem('token')
            const { id } = jwt_decode(token)
            const response = await api.get('idx/segment', { headers: { user_id: id } })

            setSegments(response.data)
        }

        loadDisciplines()
        loadPrices()
        loadSegments()

    }, [render])

    return (
        <>
            <div id={'el'} ref={el}></div>
            <Box mb={5}>
                <Typography
                    component="h1"
                    variant="h6"
                    color="primary"
                    noWrap
                    className={classes.title}>
                    <b>
                        <small>
                            &#187; Tabela de Preços
                        </small>
                    </b>
                </Typography>
            </Box>

            {/* Components */}
            <Grid container spacing={3}>

                {/* Saved/Update Coupons */}
                <Grid item xs={12}>
                    <Box mb={3}>
                        <Paper className={classes.paper}>
                            <Box mb={3}>
                                <Typography
                                    component="h1"
                                    variant="h6"
                                    color="primary"
                                    className={classes.title}>
                                    <b>
                                        <small>Nova Modalidade 	&#38; Preço</small>
                                    </b>
                                </Typography>

                                <Typography
                                    component="p"
                                    color="textSecondary"
                                    className={classes.title}>
                                    <b>
                                        <small>
                                            Cuidado, está ação não poderá ser desfeita.
                                    </small>
                                    </b>
                                </Typography>
                            </Box>

                            {
                                error !== '' ? (
                                    <Box mt={3} mb={2}>
                                        <Alert severity="error">
                                            <AlertTitle>Alerta</AlertTitle>
                                            {error}
                                        </Alert>
                                    </Box>
                                ) : null
                            }

                            {
                                success !== '' ? (
                                    <Box mt={3} mb={2}>
                                        <Alert severity="success">
                                            <AlertTitle>Alerta</AlertTitle>
                                            {success}
                                        </Alert>
                                    </Box>
                                ) : null
                            }

                            <form onSubmit={hundleSubmit} className={classes.form} noValidate>
                                <Grid container spacing={2}
                                    justify="center"
                                    alignItems="center">
                                    <Grid item
                                        xs={8}
                                        lg={6}
                                        md={8}
                                        sm={12}
                                    >

                                        <Grid item xs={12}>
                                            <Box mb={1}>
                                                <InputLabel>Disciplina</InputLabel>
                                            </Box>

                                            <Select
                                                variant="filled"
                                                margin="normal"
                                                required
                                                fullWidth
                                                defaultValue={disciplineCurrent}
                                                onChange={event => setDisciplineCurrent(event.target.value)}
                                            >
                                                {disciplines.map(discipline => (
                                                    <MenuItem
                                                        key={discipline.id}
                                                        value={discipline.id}>
                                                        {discipline.title}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Box mb={1} mt={1}>
                                                <InputLabel>Modalidade</InputLabel>
                                            </Box>

                                            <Select
                                                variant="filled"
                                                margin="normal"
                                                required
                                                fullWidth
                                                defaultValue={modality}
                                                onChange={event => setModality(event.target.value)}
                                            >
                                                <MenuItem value='Presencial'>Presencial</MenuItem>
                                                <MenuItem value='Online'>Online</MenuItem>
                                            </Select>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                variant="filled"
                                                margin="normal"
                                                label="Preço"
                                                required
                                                fullWidth
                                                inputProps={{ min: "0", step: "1" }}
                                                type="number"
                                                value={priceCurrent}
                                                onChange={event => setPricesCurrent(event.target.value)}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Box mt={2}>
                                                <Button
                                                    type="submit"
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.submit}>
                                                    Adicionar Preço </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>

                        </Paper>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Paper className={classes.paper}>

                        <React.Fragment>

                            <Box mb={5}>
                                <Title> Tabela de preços cadastrados </Title>
                            </Box>

                            <Box pb={5}>
                                <Box pb={5}>
                                    <Alert severity="success">
                                        <small>
                                            <b>Total de Registros: </b> {prices.length}
                                        </small>
                                    </Alert>
                                </Box>

                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Preço</TableCell>
                                            <TableCell>Modalidade</TableCell>
                                            <TableCell>Segmento</TableCell>
                                            <TableCell>Disciplina</TableCell>
                                            <TableCell>Registrado em</TableCell>
                                            <TableCell align="right">*</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {prices.map(price => (
                                            <TableRow key={price.id}>
                                                <TableCell>
                                                    <TextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        label="Título"
                                                        defaultValue={price.price}
                                                        onChange={event => setNewPrice(event.target.value)}
                                                    />

                                                </TableCell>
                                                <TableCell>{price.modality}</TableCell>
                                                <TableCell>{segmentFormat(price.idDiscipline)}</TableCell>
                                                <TableCell>{disciplineFormat(price.idDiscipline)}</TableCell>
                                                <TableCell>{price.createdAt}</TableCell>
                                                <TableCell align="right">
                                                    <Box mb={1}>
                                                        <IconButton
                                                            onClick={() => { _priceEdit(price.id, newPrice) }}
                                                            color="primary"
                                                            aria-label="Editar Segmento">
                                                            <Save />
                                                        </IconButton>
                                                    </Box>

                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </React.Fragment>

                    </Paper>
                </Grid>

            </Grid>
        </>
    )
}
