import React, { useState, useEffect, useRef } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import { Alert, AlertTitle } from '@material-ui/lab'
import Title from '../../components/Title'
import jwt_decode from 'jwt-decode'
import Button from '@material-ui/core/Button'

// icon
import Save from '@material-ui/icons/Save'

//styles
import styles from './styles'

// api
import api from '../../services/api'

export default function Segment() {

    // states
    const [number, setNumber] = useState('')
    const [link, setLink] = useState('')
    const [newNumber, setNewNumber] = useState('')
    const [newLink, setNewLink] = useState('')
    const [version, setVersion] = useState([])
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')
    const [render, setRender] = useState('')

    const el = useRef(null)

    // css
    const classes = styles()

    async function hundleSubmit(event) {
        event.preventDefault()
        alert('ATENÇÃO ESTE BOTÃO FOI DESABILITADO PARA MANTER A INTEGRIDADE DO CÓDIGO! DESCOMENTE O CÓDIGO PARA CONTINUAR.')

    //     setSuccess('')
    //     setError('')

    //     if ((!number) && ((!link))) {
    //         setError('Preencha todos os campos')
    //         setTimeout(() => { setError('') }, 6000)
    //         return
    //     }

    //     const token = await localStorage.getItem('token')
    //     const { id } = jwt_decode(token)
    //     const response = await api.post('/', { version: number, link }, { headers: { admin_id: id } })

    //     if (response.data) {

    //     if (response.data.alert) {
    //          setError(response.data.alert)
    //          return
    //     }

    //         setSuccess('cadastrado com sucesso!')
    //         setRender((new Date()).getMilliseconds())
    //         setTimeout(() => { setSuccess('') }, 6000)
    //     } else {
    //         setError('Erro ao cadastrar!')
    //         setTimeout(() => { setError('') }, 6000)
    //     }
    }

    async function _vEdit(data, api_version, api_link) {

        setSuccess('')
        setError('')

        if (newNumber === '') { api_version = data.version }
        if (newLink === '') { api_link = data.link }

        const api_id = data.id

        const token = await localStorage.getItem('token')
        const { id } = jwt_decode(token)
        const response = await api.put(`${api_id}`, { version: api_version, link: api_link }, {
            headers: { admin_id: id }
        })

        if (response.data) {
            setSuccess('Atualizado com sucesso!')
            setRender((new Date()).getMilliseconds())
            el.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
            setTimeout(() => { setSuccess('') }, 8000)
        } else {
            setError('Erro ao atualizar! Verifique se esse segmento já existe.')
            el.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
            setTimeout(() => { setError('') }, 8000)
        }

        setNewLink('')
        setNewNumber('')
    }

    useEffect(() => {

        async function loadSegments() {
            const token = await localStorage.getItem('token')
            const { id } = jwt_decode(token)
            const response = await api.get('', { headers: { _id: id } })

            setVersion(response.data)
        }

        loadSegments()
    }, [render])

    return (
        <>
            <div id={'el'} ref={el}></div>
            <Box mb={5}>
                <Typography
                    component="h1"
                    variant="h6"
                    color="primary"
                    noWrap
                    className={classes.title}>
                    <b>
                        <small>
                            &#187; Versão
                        </small>
                    </b>
                </Typography>
            </Box>

            {/* Components */}
            <Grid container spacing={3}>

                {/* Saved/Update Segments */}
                <Grid item xs={12}>
                    <Box mb={3}>
                        <Paper className={classes.paper}>
                            <Box mb={3}>
                                <Typography
                                    component="h1"
                                    variant="h6"
                                    color="primary"
                                    className={classes.title}>
                                    <b>
                                        <small>Adicionar Versionamento</small>
                                    </b>
                                </Typography>

                                <Typography
                                    component="p"
                                    color="textSecondary"
                                    className={classes.title}>
                                    <b>
                                        <small>
                                            Cuidado, está ação não poderá ser desfeita.
                                    </small>
                                    </b>
                                </Typography>
                            </Box>

                            {
                                error !== '' ? (
                                    <Box mt={3} mb={2}>
                                        <Alert severity="error">
                                            <AlertTitle>Alerta</AlertTitle>
                                            {error}
                                        </Alert>
                                    </Box>
                                ) : null
                            }

                            {
                                success !== '' ? (
                                    <Box mt={3} mb={2}>
                                        <Alert severity="success">
                                            <AlertTitle>Alerta</AlertTitle>
                                            {success}
                                        </Alert>
                                    </Box>
                                ) : null
                            }

                            <form onSubmit={hundleSubmit} className={classes.form} noValidate>
                                <Grid container spacing={2}
                                    justify="center"
                                    alignItems="center">
                                    <Grid item
                                        xs={8}
                                        lg={6}
                                        md={8}
                                        sm={12}
                                    >

                                        <Grid item xs={12}>
                                            <TextField
                                                variant="filled"
                                                margin="normal"
                                                required
                                                fullWidth
                                                label="Versão"
                                                value={number}
                                                onChange={event => setNumber(event.target.value)}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                variant="filled"
                                                margin="normal"
                                                required
                                                fullWidth
                                                label="Link"
                                                value={link}
                                                onChange={event => setLink(event.target.value)}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Box mt={2}>
                                                <Button
                                                    type="submit"
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.submit}>
                                                    Cadastrar &amp; Atualizar </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>

                        </Paper>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Paper className={classes.paper}>

                        <React.Fragment>

                            <Box mb={5}>
                                <Title> Versão do aplicativo </Title>
                            </Box>

                            {
                                error !== '' ? (
                                    <Box mb={5}>
                                        <Alert severity="error">
                                            <AlertTitle>Alerta</AlertTitle>
                                            {error}
                                        </Alert>
                                    </Box>
                                ) : null
                            }

                            {
                                success !== '' ? (
                                    <Box mb={5}>
                                        <Alert severity="success">
                                            <AlertTitle>Alerta</AlertTitle>
                                            {success}
                                        </Alert>
                                    </Box>
                                ) : null
                            }

                            <Box pb={5}>

                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Versão</TableCell>
                                            <TableCell>Redirecionamento</TableCell>
                                            <TableCell>Registrado em</TableCell>
                                            <TableCell align="right">*</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {version.map(v => (
                                            <TableRow key={v.id}>
                                                <TableCell className={classes.textCapitalize}>

                                                    <TextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        label="Versão"
                                                        defaultValue={v.version}
                                                        onChange={event => setNewNumber(event.target.value)}
                                                    />

                                                </TableCell>

                                                <TableCell className={classes.textCapitalize}>

                                                    <TextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        label="Link"
                                                        defaultValue={v.link}
                                                        onChange={event => setNewLink(event.target.value)}
                                                    />

                                                </TableCell>

                                                <TableCell>{v.createdAt}</TableCell>
                                                <TableCell align="right">
                                                    <Box mb={1}>
                                                        <IconButton
                                                            onClick={() => { _vEdit(v, newNumber, newLink) }}
                                                            color="primary"
                                                            aria-label="Editar Segmento">
                                                            <Save />
                                                        </IconButton>
                                                    </Box>

                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </React.Fragment>

                    </Paper>
                </Grid>

            </Grid>
        </>
    )
}
