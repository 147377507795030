import React, { useState, useEffect, useRef } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { Alert, AlertTitle } from '@material-ui/lab'
import Title from '../../components/Title'
import jwt_decode from 'jwt-decode'
import Button from '@material-ui/core/Button'

// icon
import Save from '@material-ui/icons/Save'

//styles
import styles from './styles'

// api
import api from '../../services/api'

export default function Segment() {

    // states
    const [title, setTitle] = useState('')
    const [icon, setIcon] = useState('')
    const [newTitle, setNewTitle] = useState('')
    const [newIcon, setNewIcon] = useState('')
    const [segments, setSegments] = useState([])
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')
    const [render, setRender] = useState('')

    const el = useRef(null)

    // css
    const classes = styles()

    async function hundleSubmit(event) {
        event.preventDefault()

        setSuccess('')
        setError('')

        if ((!title)) {
            setError('Preencha o campo de título.')
            setTimeout(() => { setError('') }, 6000)
            return
        }

        if ((!icon)) {
            setError('Escolha ao menos um vetor.')
            setTimeout(() => { setError('') }, 6000)
            return
        }

        const token = await localStorage.getItem('token')
        const { id } = jwt_decode(token)
        const response = await api.post('str/segment', { title, icon }, { headers: { user_id: id } })

        if (response.data) {
            setSuccess('cadastrado com sucesso!')
            setRender((new Date()).getMilliseconds())
            setTimeout(() => { setSuccess('') }, 6000)
        } else {
            setError('Erro ao cadastrar! Verifique se esse segmento já existe.')
            setTimeout(() => { setError('') }, 6000)
        }
    }

    async function _segmentEdit(edit_id, edit_title, edit_icon, default_icon) {

        setSuccess('')
        setError('')

        if ((!edit_id) || (edit_title === '')) {
            setError('Parece que não ocorreu nenhuma modificação entre o título antigo e o novo.')
            el.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
            setTimeout(() => { setError('') }, 8000)
            return
        }

        if ((!edit_icon) || (edit_icon === '')) {
            edit_icon = default_icon
        }
        
        const token = await localStorage.getItem('token')
        const { id } = jwt_decode(token)
        const response = await api.put(`upd/segment/${edit_id}`, { title: edit_title, icon: edit_icon }, { headers: { user_id: id } })

        if (response.data) {
            setSuccess('Atualizado com sucesso!')
            setRender((new Date()).getMilliseconds())
            el.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
            setTimeout(() => { setSuccess('') }, 8000)
        } else {
            setError('Erro ao atualizar! Verifique se esse segmento já existe.')
            el.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
            setTimeout(() => { setError('') }, 8000)
        }

        setNewTitle('')
        setNewIcon('')
    }

    useEffect(() => {

        async function loadSegments() {
            const token = await localStorage.getItem('token')
            const { id } = jwt_decode(token)
            const response = await api.get('idx/segment', { headers: { user_id: id } })

            setSegments(response.data)
        }

        loadSegments()
    }, [render])

    return (
        <>
            <div id={'el'} ref={el}></div>
            <Box mb={5}>
                <Typography
                    component="h1"
                    variant="h6"
                    color="primary"
                    noWrap
                    className={classes.title}>
                    <b>
                        <small>
                            &#187; Segmentos
                        </small>
                    </b>
                </Typography>
            </Box>

            {/* Components */}
            <Grid container spacing={3}>

                {/* Saved/Update Segments */}
                <Grid item xs={12}>
                    <Box mb={3}>
                        <Paper className={classes.paper}>
                            <Box mb={3}>
                                <Typography
                                    component="h1"
                                    variant="h6"
                                    color="primary"
                                    className={classes.title}>
                                    <b>
                                        <small>Novo Segmento</small>
                                    </b>
                                </Typography>

                                <Typography
                                    component="p"
                                    color="textSecondary"
                                    className={classes.title}>
                                    <b>
                                        <small>
                                            Cuidado, está ação não poderá ser desfeita.
                                    </small>
                                    </b>
                                </Typography>
                            </Box>

                            {
                                error !== '' ? (
                                    <Box mt={3} mb={2}>
                                        <Alert severity="error">
                                            <AlertTitle>Alerta</AlertTitle>
                                            {error}
                                        </Alert>
                                    </Box>
                                ) : null
                            }

                            {
                                success !== '' ? (
                                    <Box mt={3} mb={2}>
                                        <Alert severity="success">
                                            <AlertTitle>Alerta</AlertTitle>
                                            {success}
                                        </Alert>
                                    </Box>
                                ) : null
                            }

                            <form onSubmit={hundleSubmit} className={classes.form} noValidate>
                                <Grid container spacing={2}
                                    justify="center"
                                    alignItems="center">
                                    <Grid item
                                        xs={8}
                                        lg={6}
                                        md={8}
                                        sm={12}
                                    >

                                        <Grid item xs={12}>
                                            <TextField
                                                variant="filled"
                                                margin="normal"
                                                required
                                                fullWidth
                                                label="Título"
                                                value={title}
                                                onChange={event => setTitle(event.target.value)}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Box mb={1} mt={1}>
                                                <InputLabel>Ícone Vetorial</InputLabel>
                                            </Box>

                                            <Select
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                fullWidth
                                                defaultValue={icon}
                                                onChange={event => setIcon(event.target.value)}
                                            >
                                                <MenuItem value={'envelope-o'}>
                                                    envelope-o
                                                </MenuItem>
                                                <MenuItem value={'star-o'}>
                                                    star-o
                                                </MenuItem>
                                                <MenuItem value={'home'}>
                                                    home
                                                </MenuItem>
                                                <MenuItem value={'flag'}>
                                                    flag
                                                </MenuItem>
                                                <MenuItem value={'user'}>
                                                    user
                                                </MenuItem>
                                                <MenuItem value={'book'}>
                                                    book
                                                </MenuItem>
                                                <MenuItem value={'tag'}>
                                                    tag
                                                </MenuItem>
                                                <MenuItem value={'bookmark'}>
                                                    bookmark
                                                </MenuItem>
                                                <MenuItem value={'send'}>
                                                    send
                                                </MenuItem>
                                                <MenuItem value={'cube'}>
                                                    cube
                                                </MenuItem>
                                                <MenuItem value={'university'}>
                                                    university
                                                </MenuItem>
                                                <MenuItem value={'graduation-cap'}>
                                                    graduation-cap
                                                </MenuItem>
                                                <MenuItem value={'rocket'}>
                                                    rocket
                                                </MenuItem>
                                                <MenuItem value={'calendar'}>
                                                    calendar
                                                </MenuItem>
                                                <MenuItem value={'plus'}>
                                                    plus
                                                </MenuItem>
                                                <MenuItem value={'folder-open-o'}>
                                                    folder-open-o
                                                </MenuItem>
                                                <MenuItem value={'magic'}>
                                                    magic
                                                </MenuItem>
                                                <MenuItem value={'pencil-square-o'}>
                                                    pencil-square-o
                                                </MenuItem>
                                            </Select>
                                            <Box mb={1} mt={1}>
                                                <InputLabel>
                                                    <span className={classes.vectorSmall}>
                                                        Veja os vetores em em <a
                                                            className={classes.vectorIcons}
                                                            rel="noopener noreferrer"
                                                            href="https://oblador.github.io/react-native-vector-icons" target="_blank"> https://oblador.github.io/react-native-vector-icons
                                                    </a>
                                                    </span>
                                                </InputLabel>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Box mt={2}>
                                                <Button
                                                    type="submit"
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.submit}>
                                                    Cadastrar &amp; Atualizar </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>

                        </Paper>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Paper className={classes.paper}>

                        <React.Fragment>

                            <Box mb={5}>
                                <Title> Segmentos cadastrados </Title>
                            </Box>

                            <Box pb={5}>
                                <Box pb={5}>
                                    <Alert severity="success">
                                        <small>
                                            <b>Total de Registros: </b> {segments.length}
                                        </small>
                                    </Alert>
                                </Box>

                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Título</TableCell>
                                            <TableCell>Ícone Vetorial</TableCell>
                                            <TableCell>Registrado em</TableCell>
                                            <TableCell align="right">*</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {segments.map(segment => (
                                            <TableRow key={segment.id}>
                                                <TableCell className={classes.textCapitalize}>

                                                    <TextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        label="Título"
                                                        defaultValue={segment.title}
                                                        onChange={event => setNewTitle(event.target.value)}
                                                    />

                                                </TableCell>
                                                <TableCell>
                                                    <Box mt={1}>
                                                        <Select
                                                            variant="outlined"
                                                            margin="normal"
                                                            required
                                                            fullWidth
                                                            defaultValue={segment.icon}
                                                            onChange={event => setNewIcon(event.target.value)}
                                                        >
                                                            <MenuItem value={'envelope-o'}>
                                                                envelope-o
                                                        </MenuItem>
                                                            <MenuItem value={'star-o'}>
                                                                star-o
                                                        </MenuItem>
                                                            <MenuItem value={'home'}>
                                                                home
                                                        </MenuItem>
                                                            <MenuItem value={'flag'}>
                                                                flag
                                                        </MenuItem>
                                                            <MenuItem value={'user'}>
                                                                user
                                                        </MenuItem>
                                                            <MenuItem value={'book'}>
                                                                book
                                                        </MenuItem>
                                                            <MenuItem value={'tag'}>
                                                                tag
                                                        </MenuItem>
                                                            <MenuItem value={'bookmark'}>
                                                                bookmark
                                                        </MenuItem>
                                                            <MenuItem value={'send'}>
                                                                send
                                                        </MenuItem>
                                                            <MenuItem value={'cube'}>
                                                                cube
                                                        </MenuItem>
                                                            <MenuItem value={'university'}>
                                                                university
                                                        </MenuItem>
                                                            <MenuItem value={'graduation-cap'}>
                                                                graduation-cap
                                                        </MenuItem>
                                                            <MenuItem value={'rocket'}>
                                                                rocket
                                                        </MenuItem>
                                                            <MenuItem value={'calendar'}>
                                                                calendar
                                                        </MenuItem>
                                                            <MenuItem value={'plus'}>
                                                                plus
                                                        </MenuItem>
                                                            <MenuItem value={'folder-open-o'}>
                                                                folder-open-o
                                                        </MenuItem>
                                                            <MenuItem value={'magic'}>
                                                                magic
                                                        </MenuItem>
                                                            <MenuItem value={'pencil-square-o'}>
                                                                pencil-square-o
                                                        </MenuItem>
                                                        </Select>
                                                    </Box>

                                                </TableCell>
                                                <TableCell>{segment.createdAt}</TableCell>
                                                <TableCell align="right">
                                                    <Box mb={1}>
                                                        <IconButton
                                                            onClick={() => { _segmentEdit(segment.id, newTitle, newIcon, segment.icon) }}
                                                            color="primary"
                                                            aria-label="Editar Segmento">
                                                            <Save />
                                                        </IconButton>
                                                    </Box>

                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </React.Fragment>

                    </Paper>
                </Grid>

            </Grid>
        </>
    )
}
