import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => ({

    title: {
        flexGrow: 1,
    },

    textCapitalize: {
        textTransform: 'capitalize'
    },

    resized: {
        
    },

    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    }

}))

export default styles