import { makeStyles } from '@material-ui/core/styles'

//img
import banner from '../../assets/banner.png'

const styles = makeStyles(theme => ({

    root: {
        height: '100vh'
    },

    image: {
        // `linear-gradient(rgba(1, 11, 21, 0.45), rgba(4, 32, 60, 0.541)), url(${banner})`
        backgroundImage: `url(${banner})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
        backgroundSize: 'cover',
        backgroundPosition: 'right'
    },

    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },

    form: {
        width: '100%',
        marginTop: theme.spacing(1)
    },

    submit: {
        margin: theme.spacing(3, 0, 2)
    }

}))

export default styles