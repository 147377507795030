import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { Alert } from '@material-ui/lab'
import Title from '../Title'
import jwt_decode from 'jwt-decode'

// api
import api from '../../services/api'

//styles
import styles from './styles'

// icon
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

export default function Show({ match, history }) {

    // css
    const classes = styles()

    // states
    const [scheduling, setScheduling] = useState([])
    const [segments, setSegments] = useState([])
    const [disciplines, setDisciplines] = useState([])
    const [users, setUsers] = useState([])
    const [address, setAddress] = useState([])
    const [status, setStatus] = useState('pendente')

    // functions
    function _dateName(user_id) {
        for (var i = 0; i < users.length; i++) {
            let user = users[i].id
            if (user === user_id) {
                const name = users[i].name
                return name
            }
            // return null
        }
    }

    function _dateEmail(user_id) {
        for (var i = 0; i < users.length; i++) {
            let user = users[i].id
            if (user === user_id) {
                const email = users[i].email
                return email
            }
            // return null
        }
    }

    function _datePhone(user_id) {
        for (var i = 0; i < users.length; i++) {
            let user = users[i].id
            if (user === user_id) {
                const phone = users[i].phone
                return phone
            }
            // return null
        }
    }

    function _dateSegments(segment_id) {
        for (var i = 0; i < segments.length; i++) {
            let segment = segments[i].id
            if (segment === segment_id) {
                const title = segments[i].title
                return title
            }
            // return null
        }
    }

    function _dateDisciplines(discipline_id) {
        for (var i = 0; i < disciplines.length; i++) {
            let discipline = disciplines[i].id
            if (discipline === discipline_id) {
                const title = disciplines[i].title
                return title
            }
            // return null
        }
    }

    function _dateAddress(user_id) {
        for (var i = 0; i < address.length; i++) {
            let street = address[i].idAccount
            if (street === user_id) {
                const newAddress = address[i].street
                return newAddress
            }
            // return null
        }
    }

    function _dateState(user_id) {
        for (var i = 0; i < address.length; i++) {
            let initials = address[i].idAccount
            if (initials === user_id) {
                const newState = address[i].state
                return newState
            }
            // return null
        }
    }

    async function handleSubmit(new_status, class_id) {

        try {
            if (new_status !== 'edit') {
                const token = await localStorage.getItem('token')
                const { id } = jwt_decode(token)

                const response = await api.put(`/upd/class/${class_id}`, {
                    status: new_status
                }, {
                    headers: { admin_id: id }
                })

                console.log(JSON.stringify(response.data))
            }
            setStatus(new_status)
        } catch (e) {
            alert('ERRO:' + e + 'Aguarde alguns segundos, recarregue a página e tente novamente.')
        }
    }

    useEffect(() => {
        async function load() {
            let { class_id } = match.params

            const responseClass = await api.get('shw/class', { headers: { class_id } })
            setScheduling(responseClass.data.response)
            const { status } = responseClass.data.response
            setStatus(status)

            const token = await localStorage.getItem('token')
            const { id } = jwt_decode(token)

            const responseUser = await api.get('leads/account', { headers: { user_id: id } })
            setUsers(responseUser.data)

            const responseSegments = await api.get('idx/segment', { headers: { user_id: id } })
            setSegments(responseSegments.data)

            const responseDisciplines = await api.get('idx/discipline', { headers: { user_id: id } })
            setDisciplines(responseDisciplines.data)

            const responseAddress = await api.get('idx/address', { headers: { admin_id: id } })
            setAddress(responseAddress.data)


        }

        load()
    }, [match.params, status])

    return (
        <React.Fragment>
            <Box mb={5}>
                <IconButton
                    onClick={() => {
                        history.push('/dashboard')
                    }}
                    className={classes.btnIcon}
                    color="primary"
                    aria-label="página anterior">
                    <ArrowBackIcon />
                </IconButton>
            </Box>

            <Box pb={5}>
                <Paper className={classes.paper} square>
                    <Box pt={2}>
                        <Title>
                            <small className={classes.bold}>
                                DADOS PESSOAIS
                            </small>
                        </Title>
                    </Box>
                    <Box mt={5} pb={2}>
                        <Table size="medium">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <strong className={classes.titleRow}>
                                            Nome
                                        </strong>
                                    </TableCell>
                                    <TableCell>
                                        <strong className={classes.titleRow}>
                                            Email
                                        </strong>
                                    </TableCell>
                                    <TableCell align="right">
                                        <strong className={classes.titleRow}>
                                            Telefone
                                        </strong>
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <TableRow >
                                    <TableCell>
                                        <span className={classes.textCapitalize}>
                                            {_dateName(scheduling.idAccount)}
                                        </span>
                                    </TableCell>
                                    <TableCell>{_dateEmail(scheduling.idAccount)}</TableCell>
                                    <TableCell align="right">{_datePhone(scheduling.idAccount)}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Box>
                </Paper>
            </Box>

            <Box pb={5}>
                <Paper className={classes.paper} variant="elevation" square>
                    <Box pt={2}>
                        <Title>
                            <small className={classes.bold}>
                                ENDEREÇO
                            </small>
                        </Title>
                    </Box>
                    <Box mt={5} pb={2}>
                        <Table size="medium">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <strong className={classes.titleRow}>
                                            Endereço
                                        </strong>
                                    </TableCell>
                                    <TableCell align="right">
                                        <strong className={classes.titleRow}>
                                            ESTADO
                                        </strong>
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        {_dateAddress(scheduling.idAccount)}
                                    </TableCell>
                                    <TableCell align="right">
                                        <span className={classes.textUppercase}>
                                            {_dateState(scheduling.idAccount)}
                                        </span>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Box>
                </Paper>
            </Box>

            <Box pb={5}>
                <Paper className={classes.paper} variant="elevation" square>
                    <Box pt={2}>
                        <Title>
                            <small className={classes.bold}>
                                AGENDAMENTO
                            </small>
                        </Title>
                    </Box>
                    <Box mt={5} pb={2}>
                        <Table size="medium">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <strong className={classes.titleRow}>
                                            Data do agendamento
                                        </strong>
                                    </TableCell>
                                    <TableCell>
                                        <strong className={classes.titleRow}>
                                            Horário do agendamento
                                        </strong>
                                    </TableCell>
                                    <TableCell>
                                        <strong className={classes.titleRow}>
                                            Duração
                                        </strong>
                                    </TableCell>
                                    <TableCell>
                                        <strong className={classes.titleRow}>
                                            Status Atual
                                        </strong>
                                    </TableCell>
                                    <TableCell align="right">
                                        <strong className={classes.titleRow}>
                                            Novo Status (Selecione)
                                        </strong>
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <TableRow>
                                    <TableCell>{scheduling.date}</TableCell>
                                    <TableCell>{scheduling.time}</TableCell>
                                    <TableCell>
                                        {scheduling.hours}
                                        {(scheduling.hours >= 1) ? ' Horas' : ' Hora'}
                                    </TableCell>
                                    <TableCell>
                                        {
                                            (scheduling.status === 'pendente') &&
                                            <Alert severity="warning" color="warning">
                                                <span className={classes.status}>
                                                    {scheduling.status}
                                                </span>
                                            </Alert>
                                        }

                                        {
                                            (scheduling.status === 'recusado') &&
                                            <Alert severity="error" color="error">
                                                <span className={classes.status}>
                                                    {scheduling.status}
                                                </span>
                                            </Alert>
                                        }

                                        {
                                            (scheduling.status === 'aceito') &&
                                            <Alert severity="info" color="info">
                                                <span className={classes.status}>
                                                    {scheduling.status}
                                                </span>
                                            </Alert>
                                        }

                                        {
                                            (scheduling.status === 'finalizado') &&
                                            <Alert severity="success" color="success">
                                                <span className={classes.status}>
                                                    {scheduling.status}
                                                </span>
                                            </Alert>
                                        }
                                    </TableCell>
                                    <TableCell align="right">
                                        <Select
                                            variant="outlined"
                                            required
                                            defaultValue={scheduling.status}
                                            onChange={event => {
                                                handleSubmit(event.target.value, scheduling.id)
                                            }}
                                        >
                                            <MenuItem value='pendente'>pendente</MenuItem>
                                            <MenuItem value='recusado'>recusado</MenuItem>
                                            <MenuItem value='aceito'>aceito</MenuItem>
                                            <MenuItem value='finalizado'>finalizado</MenuItem>
                                        </Select>

                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Box>
                </Paper>
            </Box>

            <Box pb={5}>
                <Paper className={classes.paper} variant="elevation" square>
                    <Box pt={2}>
                        <Title>
                            <small className={classes.bold}>
                                AULA
                            </small>
                        </Title>
                    </Box>
                    <Box mt={5} pb={2}>
                        <Table size="medium">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <strong className={classes.titleRow}>
                                            Modalidade
                                        </strong>
                                    </TableCell>
                                    <TableCell>
                                        <strong className={classes.titleRow}>
                                            Segmento
                                        </strong>
                                    </TableCell>
                                    <TableCell>
                                        <strong className={classes.titleRow}>
                                            Disciplina
                                        </strong>
                                    </TableCell>
                                    <TableCell align="right">
                                        <strong className={classes.titleRow}>
                                            Total
                                        </strong>
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <span className={classes.textUppercase}>
                                            {scheduling.modality}
                                        </span>
                                    </TableCell>
                                    <TableCell>{_dateSegments(scheduling.idSegment)}</TableCell>
                                    <TableCell>{_dateDisciplines(scheduling.idDiscipline)}</TableCell>
                                    <TableCell align="right">{(scheduling.tot)} R$</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Box>
                </Paper>
            </Box>

        </React.Fragment>
    )
}
