import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => ({

    title: {
        flexGrow: 1,
    },

    status: {
        textTransform: "uppercase",
        fontSize: 11,
        fontWeight: 600
    },

    textUppercase: {
        textTransform: "uppercase"
    },

    textCapitalize: {
        textTransform: "capitalize"
    },

    bold: {
        fontWeight: "900"
    },

    titleRow: {
        color: "#666",
        fontWeight: "900"
    },

    resized: {
        
    },

    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },

    btnIcon: {
        backgroundColor: '#eee'
    }

}))

export default styles