import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'

// authenticated route
import PrivateRoute from './auth/Auth'

// pages
import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import Logout from './pages/Logout'

export default function Routes() {

    return (
        <BrowserRouter>
            <Switch>

                <Route path="/" exact
                    component={Login} />

                <PrivateRoute path="/dashboard"
                    component={Dashboard} />

                <Route path="/logout"
                    component={Logout} />

                <Route path="*">
                    <Redirect to="/" />
                </Route>

            </Switch>
        </BrowserRouter>
    )
}
