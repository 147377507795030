import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { Alert } from '@material-ui/lab'
import Title from '../Title'
import jwt_decode from 'jwt-decode'

//styles
import styles from './styles'

// api
import api from '../../services/api'

export default function User() {

    // states
    const [users, setUsers] = useState([])

    // css
    const classes = styles()

    useEffect(() => {

        async function loadLeads() {
            const token = await localStorage.getItem('token')
            const { id } = jwt_decode(token)
            const response = await api.get('leads/account', { headers: { user_id: id } })

            setUsers(response.data)
        }

        loadLeads()
    }, [])

    return (
        <>
            <Box mb={5}>
                <Typography
                    component="h1"
                    variant="h6"
                    color="primary"
                    noWrap
                    className={classes.title}>
                    <b>
                        <small>
                            &#187; Usuários
                        </small>
                    </b>
                </Typography>
            </Box>

            {/* Components */}
            <Grid container spacing={3}>

                <Grid item xs={12}>
                    <Paper className={classes.paper}>

                        <React.Fragment>

                            <Box mb={5}>
                                <Title> Usuários cadastrados </Title>
                            </Box>

                            <Box pb={5}>
                                <Box pb={5}>
                                    <Alert severity="success">
                                        <small>
                                            <b>Total de Registros: </b> {users.length}
                                        </small>
                                    </Alert>
                                </Box>

                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nome</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell>Telefone</TableCell>
                                            <TableCell align="right">Registrado em</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {users.map(user => (
                                            <TableRow key={user.id}>
                                                <TableCell className={classes.textCapitalize}>
                                                    {user.name}
                                                </TableCell>
                                                <TableCell>{user.email}</TableCell>
                                                <TableCell>{user.phone}</TableCell>
                                                <TableCell align="right">
                                                    {user.createdAt}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </React.Fragment>

                    </Paper>
                </Grid>

            </Grid>
        </>
    )
}
