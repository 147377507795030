import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => ({
    
    modal: {
        display: 'flex',
        padding: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center',
    },

    paperModal: {
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },

    title: {
        flexGrow: 1,
    },

    titleEditable: {
        color: '#f44336'
    },

    textCapitalize: {
        textTransform: 'capitalize'
    },

    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },

    submitEditable: {
        backgroundColor: '#f44336'
    },

    vectorIcons: {
        color: '#444'
    },

    vectorSmall: {
        fontSize: '10pt'
    }

}))

export default styles